var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.EpicentralDetail && Object.keys(_vm.EpicentralDetail).length),expression:"EpicentralDetail && Object.keys(EpicentralDetail).length"}],staticClass:"text-detail"},[(_vm.banners && _vm.banners.length)?_c('TextMaterialsSlider',{attrs:{"banners":_vm.banners,"title":_vm.EpicentralDetail.name,"color":'#fff'}}):_c('div',{staticClass:"content"},[_c('div',{staticClass:"text-detail__title",domProps:{"innerHTML":_vm._s(_vm.EpicentralDetail.name)}})]),_c('Breadcrumbs',{attrs:{"pages":[
        { name: 'Epicentral', link: { name: 'Epicentral' } },
        {
          name: _vm.EpicentralDetail.name,
        } ]}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"text-detail__row mt-4"},[_c('div',{staticClass:"text-detail__body"},[(false)?_c('div',{staticClass:"material__tags-wrap"},[_c('div',{staticClass:"material__tags"},[(_vm.EpicentralDetail.material_type)?_c('div',{staticClass:"material__tag"},[_vm._v(" "+_vm._s(_vm.EpicentralDetail.material_type)+" ")]):_vm._e(),_vm._l((_vm.EpicentralDetail.nosology),function(nosology){return _c('div',{key:("nos" + (nosology.id)),staticClass:"material__tag",domProps:{"innerHTML":_vm._s(nosology.title)}})}),_vm._l((_vm.EpicentralDetail.disease),function(disease){return _c('div',{key:("dis" + (disease.id)),staticClass:"material__tag",domProps:{"innerHTML":_vm._s(disease.title)}})}),(_vm.EpicentralDetail.is_new && false)?_c('div',{staticClass:"material__tag material__tag_new"},[_vm._v(" Новинка ")]):_vm._e()],2),(!_vm.$route.query.access && false)?_c('Favorite',{staticClass:"large",attrs:{"watchLater":_vm.EpicentralDetail.watch_later,"favorite":_vm.EpicentralDetail.favorite},on:{"watchLater":function($event){return _vm.watchLater(
                  _vm.EpicentralDetail.watch_later ? 'DELETE' : 'POST',
                  _vm.EpicentralDetail.slug,
                  null,
                  true
                )},"favorite":function($event){return _vm.favorite(
                  _vm.EpicentralDetail.favorite ? 'DELETE' : 'POST',
                  _vm.EpicentralDetail.slug,
                  null,
                  true
                )}}}):_vm._e()],1):_vm._e(),(_vm.EpicentralDetail.material_type !== 'Видео')?_c('div',{staticClass:"failure-page__description",domProps:{"innerHTML":_vm._s(_vm.EpicentralDetail.description)}}):_vm._e(),(
              _vm.EpicentralDetail &&
              Object.keys(_vm.EpicentralDetail).length &&
              _vm.EpicentralDetail.material_type !== 'Видео'
            )?_c('div',_vm._l((_vm.filteredContent),function(info,ind){return _c('InfoDrop',{key:ind,attrs:{"color":_vm.EpicentralDetail.color,"item":info},on:{"openImage":_vm.openImage}})}),1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.EpicentralDetail.video),expression:"EpicentralDetail.video"}],staticClass:"epicentral-detail__video"},[_c('video',{ref:"videoElement",attrs:{"controls":"","src":_vm.EpicentralDetail.video,"poster":_vm.EpicentralDetail.video_preview
                  ? _vm.EpicentralDetail.video_preview
                  : null}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.EpicentralDetail.description),expression:"EpicentralDetail.description"}],ref:"description",staticClass:"epicentral-detail__subtitle",domProps:{"innerHTML":_vm._s(_vm.EpicentralDetail.description)}})])]),_c('div',{staticClass:"sticky"},[(_vm.asideItems && _vm.asideItems.length)?_c('RightAside',{attrs:{"items":_vm.asideItems},on:{"medicationsClick":_vm.medicationsClick,"calendarClick":_vm.calendarClick,"nosologyClick":_vm.nosologyClick}}):_vm._e()],1)]),_c('CoolLightBox',{attrs:{"items":_vm.items,"index":_vm.index},on:{"close":function($event){return _vm.onClose()}}}),_c('div',{staticClass:"text-detail__buttons"},[_c('router-link',{staticClass:"text-detail__load button button_empty-pink",attrs:{"to":{ name: 'Epicentral' }}},[_c('span',{staticClass:"mr-2 "},[_c('svg',{attrs:{"width":"11","height":"18","viewBox":"0 0 11 18","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M9 1.5L1.5 9L9 16.5","stroke":"currentColor","stroke-width":"1.5","stroke-linecap":"square"}})])]),_vm._v(" Назад ")]),_c('div',{staticClass:"text-detail__up button button_empty-pink",on:{"click":function($event){return _vm.toTop()}}},[_vm._v(" К началу страницы "),_c('span',{staticClass:"ml-2"},[_c('svg',{attrs:{"width":"18","height":"10","viewBox":"0 0 18 10","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1.25 8.75012L8.75 1.25012L16.25 8.75012","stroke":"currentColor","stroke-width":"1.5","stroke-linecap":"square"}})])])])],1)],1),(
        _vm.EpicentralDetail.nosology &&
        _vm.EpicentralDetail.nosology.length &&
        _vm.sliderItems.length &&
        false
      )?_c('LibrarySlider',{attrs:{"nosology":_vm.EpicentralDetail.nosology[0],"items":_vm.sliderItems,"page":'EpicentralDetail'},on:{"clickMaterials":_vm.clickMaterials,"toNosol":_vm.toNosol}}):_vm._e()],1),(_vm.EpicentralDetail === 404)?_c('div',[_c('Choch',{staticClass:"inPage"})],1):_vm._e(),(_vm.EpicentralDetail === false)?_c('div',[_c('Error',{staticClass:"inPage"})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }